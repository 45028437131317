import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { isVersion } from "../../utils/FormValidation";
import Cookies from "js-cookie";
import { FaQuestionCircle } from "react-icons/fa";

import Checkbox from "../../components/ui/Form/Checkbox";
import Input from "../../components/ui/Form/Input";
import Select from "../../components/ui/Form/Select";
import Textarea from "../../components/ui/Form/Textarea";
import Button from "../../components/ui/Button/Button";
import Tooltip from "../../components/ui/Tooltip/Tooltip";
import defaults from "../../config/forms.json";

import { MESSAGES } from "../../constants/messages";
import { useDeviceCodes } from "../../hooks/useDeviceCode";
import { useFirewareVersions } from "../../hooks/useFirewareVersion";
import { useOemModelIds } from "../../hooks/useOemModelId";
import { useFirmwareNotificationSubmit } from "../../hooks/useFirmware";

import Acknowledgement from "./Acknowledgement";

const PRODUCT_TYPE = ["Numeric", "String"];

const FormNotification = ({ firmwareDataRefetch, onClose }) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const selectedProduct = watch("deviceCode");
  const selectedModelVersionType = watch("modelVersionType");

  const { data: deviceCodeData, isFetching: isDeviceCodeDataLoading } =
    useDeviceCodes();
  const {
    data: firewareVersionData,
    isFetching: isFirewareVersionDataLoading,
    refetch: firewareVersionRefetch,
  } = useFirewareVersions(
    selectedProduct?.value?.length == 2 && selectedProduct?.value[1]
  );
  const {
    data: oemModelIdData,
    isFetching: isOemModelIdDataLoading,
    refetch: oemModelIdDataRefetch,
  } = useOemModelIds(
    selectedProduct?.value?.length == 2 && selectedProduct?.value[1]
  );
  const {
    mutate: firmwareSubmitMutate,
    isSuccess: isFirmwareSubmitSuccess,
    isError: isFirmwareSubmitError,
  } = useFirmwareNotificationSubmit();

  useEffect(() => {
    setValue("danteFirmware", "");
    if (selectedProduct !== undefined) {
      oemModelIdDataRefetch();
      firewareVersionRefetch();
    }
  }, [selectedProduct, firewareVersionRefetch, oemModelIdDataRefetch]);

  useEffect(() => {
    if (isFirmwareSubmitSuccess) {
      firmwareDataRefetch();
      onClose();
      reset();
      setValue("modelId", "");
      setValue("deviceCode", "");
      setValue("modelVersionType", "");
    }

    if (isFirmwareSubmitSuccess || isFirmwareSubmitError) {
      setIsSubmitting(false);
    }
  }, [isFirmwareSubmitSuccess, isFirmwareSubmitError]);

  const handleNotificationSubmit = (data) => {
    setIsSubmitting(true);

    firmwareSubmitMutate({
      aId: Cookies.get("aId"),
      deviceCode: data.deviceCode.value[1],
      deviceCodeName: data.deviceCode.value[0],
      danteFirmware: data.danteFirmware.value,
      modelName: data.modelName,
      modelId: data.modelId.value,
      modelVersion: data.modelVersion,
      modelVersionType: data.modelVersionType.value,
      softwareDownloadUrl: data.softwareDownloadUrl,
      releaseNotesUrl: data.releaseNotesUrl,
      comments: data.comments,
      publicationDate: Date.now(),
    });
  };

  return (
    <form
      onSubmit={handleSubmit((data) => handleNotificationSubmit(data))}
      className="grid grid-cols-2 gap-4"
    >
      <div className="col-span-2">
        <Input
          label="Manufacturer"
          value={Cookies.get("manufacturer")}
          readOnly
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <Controller
          name="deviceCode"
          control={control}
          rules={{ required: "Dante Product Family is required." }}
          render={({ field }) => (
            <Select
              field={field}
              label="Dante Product Family"
              options={
                deviceCodeData &&
                deviceCodeData.map((item) => ({
                  label: item.name,
                  value: [item.name, item.deviceCode],
                }))
              }
              setValue={setValue}
              placeholder="Select Dante Product Family"
              isLoading={isDeviceCodeDataLoading}
              error={errors.deviceCode ? errors.deviceCode.message : null}
            />
          )}
        ></Controller>
      </div>
      <div className="col-span-2 md:col-span-1">
        <Controller
          name="danteFirmware"
          control={control}
          rules={{ required: "Dante Firmware Version is required." }}
          render={({ field }) => (
            <Select
              field={field}
              label="Dante Firmware Version"
              options={
                firewareVersionData &&
                firewareVersionData.map((item) => ({
                  label: item,
                  value: item,
                }))
              }
              setValue={setValue}
              placeholder="Select Dante Firmware Version"
              isLoading={isFirewareVersionDataLoading}
              error={errors.danteFirmware?.message}
            />
          )}
        ></Controller>
      </div>
      <div className="col-span-2 md:col-span-1">
        <Input
          label={
            <Tooltip message={MESSAGES.HELP.PRODUCT_MODEL_NAME}>
              <div className="flex flex-nowrap">
                Product Model Name
                <FaQuestionCircle className="text-gray-500 text-xs self-center ml-1" />
              </div>
            </Tooltip>
          }
          placeholder="Enter Product Model Name"
          validation={{
            name: "modelName",
            register,
            rules: { required: "Product Model Name is required." },
          }}
          error={errors.modelName?.message}
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <Controller
          name="modelId"
          control={control}
          rules={{ required: "Model ID is required." }}
          render={({ field }) => (
            <Select
              isCreatable
              field={field}
              label="Model ID"
              options={
                oemModelIdData &&
                oemModelIdData.map((item) => ({
                  label: item,
                  value: item,
                }))
              }
              setValue={setValue}
              placeholder="Enter Model ID"
              isLoading={isOemModelIdDataLoading}
              error={errors.modelId?.message}
            />
          )}
        ></Controller>
      </div>
      <div className="col-span-2 md:col-span-1">
        <Controller
          name="modelVersionType"
          control={control}
          rules={{ required: "Product Version Number Type is required." }}
          render={({ field }) => (
            <Select
              field={field}
              label="Product Version Number Type"
              options={PRODUCT_TYPE.map((item) => ({
                label: item,
                value: item,
              }))}
              setValue={setValue}
              placeholder="Select Product Version Number Type"
              error={errors.modelVersionType?.message}
            />
          )}
        ></Controller>
      </div>
      <div className="col-span-2 md:col-span-1">
        <Input
          label="Product Version"
          placeholder="Enter Product Version"
          validation={{
            name: "modelVersion",
            register,
            rules: {
              required: "Product Version is required.",
              validate: (value) =>
                isVersion(value, selectedModelVersionType?.value),
            },
          }}
          error={errors.modelVersion?.message}
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <Input
          label="Software Download URL"
          placeholder="Enter Software Download URL"
          validation={{
            name: "softwareDownloadUrl",
            register,
            rules: {
              required: "Software Download URL is required.",
              maxLength: {
                value: defaults.input.maxLength,
                message: `URL cannot exceed ${defaults.input.maxLength} characters`,
              },
            },
          }}
          error={errors.softwareDownloadUrl?.message}
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <Input
          label="Release Notes URL"
          sublabel="optional"
          placeholder="Enter Release Notes URL"
          validation={{
            name: "releaseNotesUrl",
            register,
            rules: {
              maxLength: {
                value: defaults.input.maxLength,
                message: `URL cannot exceed ${defaults.input.maxLength} characters`,
              },
            },
          }}
          error={errors.releaseNotesUrl?.message}
        />
      </div>
      <div className="col-span-2">
        <Textarea
          label="Comments"
          sublabel="optional"
          placeholder="Enter Comments"
          validation={{
            name: "comments",
            register,
          }}
        />
      </div>
      <div className="col-span-2">
        <Acknowledgement />
        <div className="mt-5">
          <Checkbox
            label="I agree"
            value={1}
            validation={{
              name: "agree",
              register,
              rules: { required: "I agree field is required." },
            }}
            error={errors.agree?.message}
          />
        </div>
      </div>
      <div className="col-span-2 md:flex justify-end gap-2">
        <Button
          type="submit"
          solid
          primary
          isLoading={isSubmitting}
          className="w-full md:w-auto mt-3"
        >
          Submit Firmware Notification
        </Button>
        <Button onClick={onClose} solid gray className="w-full md:w-auto mt-3">
          Close
        </Button>
      </div>
    </form>
  );
};
export default FormNotification;
