import Cookies from "js-cookie";
import { useFetchData } from "../utils/ReactQueryHooks";
import { isData } from "../utils/HookResponse";

export const useOemModelIds = (deviceCode) =>
  useFetchData({
    key: "useOemModelIds",
    url: "firmwares/model-ids",
    headers: {
      aId: Cookies.get("aId"),
      "device-code": deviceCode,
    },
    options: {
      enabled: false,
      select: (data) => [...new Set(isData(data))],
    },
  });
