import { useState } from "react";

import Input from "../../components/ui/Form/Input";
import Label from "../../components/ui/Label/Label";
import Skeleton from "../../components/ui/Preloader/Skeleton";
import TreeView from "../../components/ui/TreeView/TreeView";

import { useLicenses } from "../../hooks/useLicense";

const Hierarchy = ({ handleSelectAccount }) => {
  const { data: licenseData, isLoading: isLicenseDataLoading } = useLicenses();
  const [accountFilter, setAccountFilter] = useState("");

  return (
    <>
      <div className="grid md:grid-cols-2 items-center">
        <div className="flex items-center justify-center md:justify-start ">
          <Label xl>Accounts</Label>
        </div>
        <div
          className={`flex justify-center md:justify-end gap-2 pt-2 md:pt-0 items-center ${
            (licenseData?.length == 0 || isLicenseDataLoading) && "invisible"
          }`}
        >
          <Input
            md
            placeholder="Search Accounts..."
            value={accountFilter}
            onChange={(e) => setAccountFilter(e.target.value)}
          />
        </div>
      </div>
      <div>
        {isLicenseDataLoading ? (
          <Skeleton />
        ) : !isLicenseDataLoading && licenseData ? (
          <TreeView
            className="py-4 pr-2 text-sm"
            data={licenseData}
            dataFilter={accountFilter}
            config={{
              id: "accountId",
              parent: "name",
              children: "accounts",
              toReturn: ["isTopLevelAccount", "licenses"],
            }}
            onSelectNode={handleSelectAccount}
            expandFirst={true}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Hierarchy;
