import Label from "../../components/ui/Label/Label";

const BannerTop = () => {
  return (
    <div className="mb-5">
      <Label xl>Dante Firmware Manager</Label>
      <p className="mt-5">
        The Dante Firmware Manager is a tool for uploading new manufacturer
        device firmware (DNT files) for access by users in the field via the
        Dante Updater software (accessible in Dante Controller). Either:{" "}
        <b className="border bg-gray-200 rounded px-2 mx-2">
          1. Choose Upload Firmware File to submit your DNT file
        </b>
        or
        <b className="border bg-gray-200 rounded px-2 mx-2">
          2. Notify end-users that new firmware is available via a URL link.
        </b>
      </p>
      <hr className="mt-5" />
    </div>
  );
};

export default BannerTop;
