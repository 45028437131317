import { DateTime } from "luxon";

import Button from "../../components/ui/Button/Button";
import Table from "../../components/ui/Table/Table";
import Label from "../../components/ui/Label/Label";

const TableSubmissions = ({
  data,
  isLoading,
  onNotificationModal,
  onFileModal,
}) => {
  const columns = [
    {
      header: "File ID",
      accessorKey: "id",
    },
    {
      header: "Upload Date",
      accessorKey: "uploadedDate",
      cell: (info) =>
        DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
    },
    {
      header: "Manufacturer",
      accessorKey: "manufacture",
    },
    {
      header: "Username",
      accessorKey: "userName",
    },
    {
      header: "Dante Product Family",
      accessorKey: "product",
    },
    {
      header: "Dante Firmware Version",
      accessorKey: "danteSwVersion",
    },
    {
      header: "Product Model Name",
      accessorKey: "modelName",
    },
    {
      header: "Product Version",
      accessorKey: "modelVersion",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
    {
      header: "Date Actioned",
      accessorKey: "actionDate",
      cell: (info) =>
        DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
    },
  ];
  return (
    <>
      <div className="grid md:grid-cols-2 items-center">
        <div className="flex items-center justify-center md:justify-start">
          <Label xl>Submissions List</Label>
        </div>
        <div className="flex justify-center md:justify-end gap-2 pt-2 md:pt-0">
          <Button onClick={() => onNotificationModal(true)} solid primary>
            Upload Firmware URL
          </Button>
          <Button onClick={() => onFileModal(true)} solid primary>
            Upload Firmware File
          </Button>
        </div>
      </div>
      <Table data={data} columns={columns} isLoading={isLoading} />
    </>
  );
};

export default TableSubmissions;
