import { FaXmark } from "react-icons/fa6";
import Button from "../Button/Button";
import Label from "../Label/Label";
import { modalSize } from "./theme";
import clsx from "clsx";

const MODAL_CLASSES = {
  overlay:
    "fixed inset-0 flex justify-center items-center transition-colors z-[999]",
  modal: "bg-white rounded-xl shadow-md transition-all relative",
  modalHeader: "flex justify-between p-4 border-b rounded-t",
  headerTitle: "text-xl font-semibold",
  modalBody: "p-4 max-h-[80vh] overflow-y-auto scrollbar-thin",
  modalFooter: "p-2",
};

const Modal = ({
  children,
  className,

  title,
  yesLabel,
  closeLabel,
  showClose,
  open,

  xl,
  lg,
  md,
  sm,

  onYes,
  onClose,
}) => {
  const buildSizeClass = () => {
    if (sm) return modalSize.sm;
    if (md) return modalSize.md;
    if (lg) return modalSize.lg;
    if (xl) return modalSize.xl;
    return modalSize.md;
  };

  const buildClassName = () => {
    return clsx(buildSizeClass(), className);
  };

  return (
    <div
      onClick={onClose}
      className={`${MODAL_CLASSES.overlay} ${
        open ? "visible bg-gray-500/70" : "invisible"
      }`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={clsx(
          MODAL_CLASSES.modal,
          buildClassName(),
          open ? "scale-100 opacity-100" : "scale-75 opacity-0"
        )}
      >
        <div className={MODAL_CLASSES.modalHeader}>
          <Label xl>{title}</Label>
          <Button onClick={onClose} text>
            <FaXmark />
          </Button>
        </div>
        <div className={MODAL_CLASSES.modalBody}>
          {children}
          <div className="flex justify-end gap-2 mt-5">
            {onYes && (
              <Button onClick={onYes} solid primary>
                {yesLabel}
              </Button>
            )}
            {onClose && showClose && (
              <Button onClick={onClose} solid gray>
                {closeLabel}
              </Button>
            )}
          </div>
        </div>
        <div className={MODAL_CLASSES.modalFooter}></div>
      </div>
    </div>
  );
};

Modal.defaultProps = {
  title: "",
  yesLabel: "Proceed",
  closeLabel: "Close",
  showClose: true,
  open: false,

  xl: false,
  lg: false,
  md: false,
  sm: false,

  onYes: null,
  onClose: null,
};

export default Modal;
