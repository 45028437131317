const theme = {
  cardHeader: {
    defaultProps: {
      shadow: false,
      floated: false,
    },
  },
  tabsBody: {
    defaultProps: {
      animate: {
        initial: { y: 250 },
        mount: { y: 0 },
        unmount: { y: 250 },
      },
    },
  },
};

export default theme;
