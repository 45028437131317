const BannerBottom = () => {
  return (
    <div className="mt-5">
      <hr />
      <div className="mt-5">
        <div className="mt-5">
          <b>Admin Role:</b> Power user. Ability to see everything in the
          portal, upload firmware, login to Module Config Tool and manage users;
          update pool balance notification threshold, add/remove contacts to
          receive pool balance notifications.
        </div>
        <div className="mt-5">
          <b>Firmware Manager Role:</b> Ability to create and view
          self-requested support cases; use Firmware Manager; login to Module
          Config Tool; view license pool balances, technical documentation (on
          OEM Home), Your Releases, News & Events, Resources and marketing
          materials.
        </div>
        <div className="mt-5">
          <b>Engineering Role:</b> Ability to create and view self-requested
          support cases; view license pool balances, technical documentation (on
          OEM Home), Your Releases, News & Events, Resources and marketing
          materials.
        </div>
        <div className="mt-5">
          <b>License Manager Role:</b> Ability to create and view self-requested
          support cases; view technical documentation (on OEM Home), Your
          Releases, News & Events, Resources and marketing materials; view
          license pool balances, update pool balance notification threshold,
          add/remove contacts to receive pool balance notifications.
        </div>
        <div className="mt-5">
          <b>Marketing Role:</b> Ability to create and view self-requested
          support cases; view News & Events, Resources, marketing materials and
          submit marketing-related web forms.
        </div>
      </div>
    </div>
  );
};

export default BannerBottom;
