const BannerBottom = () => {
  return (
    <div className="mt-5">
      <hr />
      <div className="mt-5">
        <p>
          <b>Please note:</b> Firmware submissions will be visible to all users
          of the Dante Updater software. Please only submit production firmware
          files, or notifications for production firmware.
        </p>
        <p>
          If you have any questions regarding Dante Updater, the firmware upload
          process or to remove previously submitted firmware, please contact{" "}
          <a href="mailto:oem-support@audinate.com" className="text-info">
            oem-support@audinate.com
          </a>
        </p>
        <p className="mt-5">
          <b>More Information:</b>{" "}
          <a
            href="https://www.getdante.com/support/faq/?_ct_featured_product=dante-updater"
            className="text-info border bg-gray-200 rounded px-2 mx-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dante Updater FAQs
          </a>
          <a
            href="https://dev.audinate.com/GA/dante-updater/userguide/webhelp/?_gl=1*18ozu8v*_ga*MTk4MjY5NDMxOC4xNzE3NTQxNzE1*_ga_BQJVS521EK*MTcyNDI4OTA3Ni4yNi4xLjE3MjQyOTEzMDUuMC4wLjA."
            className="text-info border bg-gray-200 rounded px-2 mx-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dante Updater User Guide
          </a>
        </p>
      </div>
    </div>
  );
};

export default BannerBottom;
