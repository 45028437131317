import { toast } from "sonner";

const TOAST_CONFIG = { duration: 5000 };

export const success = (data) => {
  toast.success(data.data?.msg, TOAST_CONFIG);
};

export const error = (error) => {
  toast.error(`${error.response?.data?.message}`, TOAST_CONFIG);
};

export const successError = (data) => {
  if (data.data?.result == "success") {
    toast.success(data.data?.msg, TOAST_CONFIG);
  } else {
    toast.error(data.data?.msg, TOAST_CONFIG);
  }
};
