import { useQuery, useMutation } from "@tanstack/react-query";
import * as request from "./ApiClient";
import * as toast from "./ApiToast";

export const useFetchData = ({
  key,
  baseURL = null,
  url,
  data = {},
  headers = {},
  options = {},
  post = false,
}) =>
  useQuery({
    queryKey: [key],
    queryFn: async () => {
      const requestConfig = { baseURL, url, data, headers };
      const response = !post
        ? await request.get(requestConfig)
        : await request.post(requestConfig);

      return response.data;
    },
    ...options,
  });

export const usePostData = ({
  baseURL = null,
  url,
  headers = {},
  options = {},
  withToastSuccess = true,
  withToastError = true,
}) =>
  useMutation({
    mutationFn: (data) => request.post({ baseURL, url, data, headers }),
    ...(withToastSuccess && { onSuccess: toast.success }),
    ...(withToastError && { onError: toast.error }),
    ...options,
  });
