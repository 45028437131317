import clsx from "clsx";

const Label = ({ children, className, sm, md, lg, xl, ...rest }) => {
  const labelClass = "block mb-1";

  const buildSizeClass = () => {
    if (sm) return "text-xs";
    if (md) return "text-sm";
    if (lg) return "";
    if (xl) return "text-lg font-semibold";
    return "text-sm";
  };

  const buildClassName = () => {
    return clsx(labelClass, buildSizeClass(), className);
  };

  return (
    <>
      <label className={buildClassName()} {...rest}>
        {children}
      </label>
    </>
  );
};

Label.defaultProps = {
  className: "",

  sm: false,
  md: false,
  lg: false,
  xl: false,
};

export default Label;
