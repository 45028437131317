import { useState } from "react";

import Modal from "../../components/ui/Modal/Modal";
import FormNotification from "./FormNotification";
import FormFile from "./FormFile";
import TableSubmissions from "./TableSubmissions";

import { useFirmwares } from "../../hooks/useFirmware";
import BannerTop from "./BannerTop";
import BannerBottom from "./BannerBottom";

const Firmware = () => {
  document.title = "Firmware Manager";

  const [isNotificationModal, setIsNotificationModal] = useState(false);
  const [isFileModal, setIsFileModal] = useState(false);

  const {
    data: firmwareData,
    isLoading: isFirmwareDataLoading,
    refetch: firmwareDataRefetch,
  } = useFirmwares();

  return (
    <>
      <BannerTop />
      <TableSubmissions
        data={firmwareData}
        isLoading={isFirmwareDataLoading}
        onNotificationModal={setIsNotificationModal}
        onFileModal={setIsFileModal}
      />
      <BannerBottom />

      <Modal
        open={isNotificationModal}
        showClose={false}
        onClose={() => setIsNotificationModal(false)}
        title="Upload Firmware URL"
        lg
      >
        <FormNotification
          firmwareDataRefetch={firmwareDataRefetch}
          onClose={() => setIsNotificationModal(false)}
        />
      </Modal>
      <Modal
        open={isFileModal}
        showClose={false}
        onClose={() => setIsFileModal(false)}
        title="Upload Firmware File"
        lg
      >
        <FormFile
          firmwareDataRefetch={firmwareDataRefetch}
          onClose={() => setIsFileModal(false)}
        />
      </Modal>
    </>
  );
};

export default Firmware;
