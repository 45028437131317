import { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { Controller, useForm } from "react-hook-form";
import Cookies from "js-cookie";

import Button from "../../components/ui/Button/Button";
import Select from "../../components/ui/Form/Select";
import Label from "../../components/ui/Label/Label";
import Table from "../../components/ui/Table/Table";
import Modal from "../../components/ui/Modal/Modal";
import Switch from "../../components/ui/Switch/Switch";

import { useUsers, useUserOffboard, useUserUpdate } from "../../hooks/useUser";
import BannerBottom from "./BannerBottom";

const User = () => {
  const {
    data: userData,
    isLoading: isUserDataLoading,
    isSuccess: isUserDataSuccess,
    refetch: userDataRefetch,
  } = useUsers();

  const {
    mutate: userOffboardMutate,
    isSuccess: userOffboardSuccess,
    isError: userOffboardError,
  } = useUserOffboard();

  const {
    mutate: userUpdateMutate,
    isSuccess: userUpdateSuccess,
    isError: userUpdateError,
  } = useUserUpdate();

  const [users, setUsers] = useState([]);
  const [isOnlyAdmin, setIsOnlyAdmin] = useState(false);

  const { control, setValue, watch } = useForm();

  const selectedFilterStatus = watch("filterStatus");
  const [filters, setFilters] = useState({
    status: "",
  });

  const [isOpenOffboardPrompt, setIsOpenOffboardPrompt] = useState(false);
  const [selectedUserOffboard, setSelectedUserOffboard] = useState(null);

  const [isOpenProfileChangePrompt, setIsOpenProfileChangePrompt] =
    useState(false);
  const [selectedUserProfileChange, setSelectedUserProfileChange] =
    useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const [isOpenStatusChangePrompt, setIsOpenStatusChangePrompt] =
    useState(false);
  const [selectedUserStatusChange, setSelectedUserStatusChange] =
    useState(null);

  const [isOpenInvalidPrompt, setIsOpenInvalidPrompt] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const profiles = [
    "Admin",
    "Firmware Manager",
    "Engineering",
    "License Manager",
    "Marketing",
  ];
  const columns = [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Profile",
      cell: ({ row }) => (
        <div className="w-[300px]">
          <Select
            options={profiles.map((item) => ({
              label: item,
              value: item,
            }))}
            placeholder={row.original.profile}
            placeholderColor="var(--color-my-black)"
            value={row.original.profile}
            onChange={(e) =>
              handleOpenProfileChangePrompt(row.original, e.value)
            }
            disabled={isSubmitting}
            isLoading={selectedUserProfileChange?.Id == row.original.Id}
            menuPortalTarget={document.body}
            isSimple
            md
          />
        </div>
      ),
      size: 400,
      canSort: false,
    },
    {
      header: "Active",
      cell: ({ row }) => (
        <Switch
          isOn={
            selectedUserStatusChange?.Id == row.original.Id
              ? !row.original.isActive
              : row.original.isActive
          }
          disabled={isSubmitting}
          isLoading={selectedUserStatusChange?.Id == row.original.Id}
          onClick={() => handleOpenStatusChangePrompt(row.original)}
        />
      ),
      size: 100,
      canSort: false,
    },
    {
      header: "Actions",
      cell: ({ row }) => (
        <Button
          solid
          danger
          disabledOnly={isSubmitting}
          isLoading={selectedUserOffboard?.Id == row.original.Id}
          onClick={() => handleOpenOffboardPrompt(row.original)}
        >
          <FaTrash />
        </Button>
      ),
      size: 100,
      canSort: false,
    },
  ];

  const handleOpenOffboardPrompt = (user) => {
    if (isOnlyAdmin && user.profile == "Admin" && user.isActive) {
      setIsOpenInvalidPrompt(true);
    } else {
      setSelectedUserOffboard(user);
      setIsOpenOffboardPrompt(true);
    }
  };

  const handleOffboard = (user) => {
    setIsSubmitting(true);
    setIsOpenOffboardPrompt(false);

    userOffboardMutate({
      aId: Cookies.get("aId"),
      userId: user.Id,
      name: user.name,
    });
  };

  const handleOpenProfileChangePrompt = (user, profile) => {
    if (isOnlyAdmin && user.profile == "Admin" && user.isActive) {
      setIsOpenInvalidPrompt(true);
    } else {
      setSelectedUserProfileChange(user);

      if (profile !== "Admin") {
        if (userData?.currentUser?.Id == user.Id) {
          setIsOpenProfileChangePrompt(true);
          setSelectedProfile(profile);
        } else {
          handleProfileChange(user, profile);
        }
      } else {
        setIsOpenProfileChangePrompt(true);
      }
    }
  };

  const handleProfileChange = (user, profile = "Admin") => {
    setIsSubmitting(true);
    setIsOpenProfileChangePrompt(false);
    setSelectedProfile(null);

    userUpdateMutate({
      aId: Cookies.get("aId"),
      userId: user.Id,
      name: user.name,
      profile: profile,
    });
  };

  const handleOpenStatusChangePrompt = (user) => {
    if (isOnlyAdmin && user.profile == "Admin" && user.isActive) {
      setIsOpenInvalidPrompt(true);
    } else {
      setSelectedUserStatusChange(user);

      if (userData?.currentUser?.Id == user.Id) {
        setIsOpenStatusChangePrompt(true);
      } else {
        handleStatusChange(user);
      }
    }
  };

  const handleStatusChange = (user) => {
    setIsSubmitting(true);
    setIsOpenStatusChangePrompt(false);

    userUpdateMutate({
      aId: Cookies.get("aId"),
      userId: user.Id,
      name: user.name,
      isActive: !user.isActive,
    });
  };

  const handleClosePrompt = () => {
    setSelectedUserOffboard(null);
    setIsOpenOffboardPrompt(false);

    setSelectedUserProfileChange(null);
    setIsOpenProfileChangePrompt(false);
    setSelectedProfile(null);

    setSelectedUserStatusChange(null);
    setIsOpenStatusChangePrompt(false);

    setIsOpenInvalidPrompt(false);
  };

  useEffect(() => {
    if (isUserDataSuccess) {
      setUsers(userData?.userList);

      setIsOnlyAdmin(
        userData?.userList?.filter(
          (item) => item.profile == "Admin" && item.isActive
        ).length <= 1
      );
    }
  }, [isUserDataSuccess, userData]);

  useEffect(() => {
    if (
      userOffboardSuccess ||
      userOffboardError ||
      userUpdateSuccess ||
      userUpdateError
    ) {
      userDataRefetch().then(() => {
        setIsSubmitting(false);
        setSelectedUserStatusChange(null);
        setSelectedUserProfileChange(null);
        handleClosePrompt();
      });
    }
  }, [
    userOffboardSuccess,
    userOffboardError,
    userUpdateSuccess,
    userUpdateError,
  ]);

  useEffect(() => {
    if (selectedFilterStatus?.value || selectedFilterStatus?.value === "") {
      setFilters((prev) => ({ ...prev, status: selectedFilterStatus.value }));
    }
  }, [selectedFilterStatus]);

  return (
    <>
      <div className="grid md:grid-cols-2 items-center">
        <div className="flex items-center justify-center md:justify-start">
          <Label xl>Manage Users</Label>
        </div>
        <div className="flex justify-center md:justify-end gap-2 pt-2 md:pt-0">
          <div className="w-[200px] items-center mb-1">
            <Controller
              name="filterStatus"
              control={control}
              render={({ field }) => (
                <Select
                  field={field}
                  options={[
                    {
                      label: "All",
                      value: "",
                    },
                    {
                      label: "Active",
                      value: "active",
                    },
                    {
                      label: "Inactive",
                      value: "inactive",
                    },
                  ]}
                  setValue={setValue}
                  placeholder="Select Status"
                  placeholderColor="var(--color-my-black)"
                  md
                />
              )}
            ></Controller>
          </div>
          <Button solid primary className="self-center hidden">
            Add User
          </Button>
        </div>
      </div>
      <Table
        data={users?.filter((item) => {
          return filters.status !== ""
            ? (item.isActive ? "active" : "inactive") === filters.status
            : true;
        })}
        columns={columns}
        isLoading={isUserDataLoading}
      />
      <Modal
        title="Are you sure?"
        open={isOpenOffboardPrompt}
        onClose={handleClosePrompt}
        closeLabel="No"
        onYes={() => handleOffboard(selectedUserOffboard)}
        yesLabel="Yes"
      >
        <p className="mb-5">
          This will remove <b>{selectedUserOffboard?.name}&apos;s</b> access to
          the DNA portal entirely. To re-enable access for{" "}
          <b>{selectedUserOffboard?.name}</b> you will have to go through the
          registration process again. Are you happy to proceed?
        </p>
      </Modal>
      <Modal
        title="Are you sure?"
        open={isOpenProfileChangePrompt}
        onClose={handleClosePrompt}
        closeLabel="No"
        onYes={() => handleProfileChange(selectedUserProfileChange)}
        yesLabel="Yes"
      >
        {userData?.currentUser?.Id == selectedUserProfileChange?.Id && (
          <>
            Are you sure you want to change your own account to{" "}
            <b>{selectedProfile}</b> Profile?
          </>
        )}
        {userData?.currentUser?.Id != selectedUserProfileChange?.Id && (
          <>
            Changing <b>{selectedUserProfileChange?.name}&apos;s</b> profile to
            Admin will allow them to administer all other users of the portal
            and provide access to firmware and licensing tools. Are you happy to
            proceed?
          </>
        )}
      </Modal>
      <Modal
        title="Are you sure?"
        open={isOpenStatusChangePrompt}
        onClose={handleClosePrompt}
        closeLabel="No"
        onYes={() => handleStatusChange(selectedUserStatusChange)}
        yesLabel="Yes"
      >
        Are you sure you want to de-activate your own account?
      </Modal>
      <Modal
        title="Invalid action"
        open={isOpenInvalidPrompt}
        onClose={handleClosePrompt}
        closeLabel="I understand"
      >
        <p className="mb-5">
          There needs to be at least one admin user profile in your account at
          all times.
        </p>
      </Modal>
      <BannerBottom />
    </>
  );
};

export default User;
