import { FaCheck, FaSpinner, FaTimes } from "react-icons/fa";

const Switch = ({ isOn, isLoading, disabled, ...rest }) => {
  return (
    <div className="flex items-center space-x-2 ">
      <button
        className={`relative p-1 inline-flex rounded-full transition-colors duration-300 ease-in-out w-[50px]
              ${isOn ? "bg-primary" : "bg-gray-300"}
              ${disabled ? "hover:!cursor-not-allowed" : ""}
              ${isLoading ? "hover:!cursor-wait animate-pulse" : ""}`}
        disabled={isLoading || disabled}
        {...rest}
      >
        <span
          className={`inline-block h-[23px] w-[23px] transform rounded-full bg-white transition-transform duration-300 ease-in-out
              ${isOn ? "translate-x-[19px]" : "translate-x-0"}`}
        >
          {isLoading && (
            <FaSpinner className="text-primary mx-auto mt-1 animate-spin" />
          )}
          {isOn && !isLoading && (
            <FaCheck className="text-primary mx-auto mt-1" />
          )}
          {!isOn && !isLoading && (
            <FaTimes className="text-gray-300 mx-auto mt-1" />
          )}
        </span>
      </button>
    </div>
  );
};

Switch.defaultProps = {
  isOn: false,
  onClick: () => {},
  isLoading: false,
  disabled: false,
};

export default Switch;
