// accepts <0-255>.<0-255>.<0-65535>
export const isVersion = (value, type) => {
  if (type == "Numeric") {
    const error = "It must be in the form of <0-255>.<0-255>.<0-65535>";
    const segments = value.split(".");

    if (segments.length !== 3) return error;

    for (let i = 0; i < 2; i++) {
      const segment = parseInt(segments[i], 10);
      if (isNaN(segment) || segment < 0 || segment > 255) {
        return error;
      }
    }

    const segment = parseInt(segments[2], 10);
    if (isNaN(segment) || segment < 0 || segment > 65535) {
      return error;
    }
  } else {
    const error = "The Product version cannot exceed 31 characters";

    if (value.length > 31) return error;
  }

  return true;
};
