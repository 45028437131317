import ReactSelect from "react-select";
import clsx from "clsx";
import CreatableSelect from "react-select/creatable";
import Error from "../Label/Error";

const Select = ({
  label,
  sublabel,
  options,
  error,
  disabled,
  placeholder,
  placeholderColor,
  isLoading,
  isCreatable,
  isSimple,
  setValue,
  field,
  menuPlacement,
  menuPortalTarget,
  onChange,

  md,
  lg,
}) => {
  const selectClass = "!rounded-md ring-1 !border-0 sm:text-sm ring-inset ";

  const buildSizeClass = () => {
    if (md) return "";
    if (lg) return "py-1";
    return "py-1";
  };

  const buildClassName = {
    control: () =>
      clsx(
        selectClass,
        buildSizeClass(),
        error ? "ring-red-500" : "ring-gray-400"
      ),
  };

  const customStyle = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      boxShadow: state.isFocused ? "0 0 0 2px black" : "",
    }),
    placeholder: (value) => ({
      ...value,
      color: placeholderColor || "#BDBDBD",
    }),
  };

  const customTheme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: "gray",
      primary25: "lightgray",
    },
  });

  return (
    <>
      <label className="block text-sm">
        {label}
        {sublabel !== "" && (
          <label className="float-end text-gray-500 text-xs">{sublabel}</label>
        )}
      </label>
      <div className="mt-1">
        {!isCreatable && !isSimple && (
          <ReactSelect
            placeholder={placeholder}
            options={options}
            styles={customStyle}
            classNames={buildClassName}
            theme={customTheme}
            menuPlacement={menuPlacement}
            menuPortalTarget={menuPortalTarget}
            onChange={(e) => {
              setValue(field.name, e);
              field.onChange(e);
            }}
            isLoading={isLoading}
            isDisabled={disabled}
            {...field}
          />
        )}

        {isSimple && (
          <ReactSelect
            placeholder={placeholder}
            options={options}
            styles={customStyle}
            classNames={buildClassName}
            theme={customTheme}
            menuPlacement={menuPlacement}
            menuPortalTarget={menuPortalTarget}
            onChange={onChange}
            isLoading={isLoading}
            isDisabled={disabled}
            {...field}
          />
        )}

        {isCreatable && (
          <CreatableSelect
            placeholder={placeholder}
            formatCreateLabel={(input) => `Enter "${input}"`}
            options={options}
            styles={customStyle}
            classNames={buildClassName}
            theme={customTheme}
            components={{
              DropdownIndicator: null,
              IndicatorSeparator: null,
              NoOptionsMessage: (props) => (
                <div
                  {...props.innerProps}
                  className="p-2 text-gray-500 text-center"
                >
                  No suggestions
                </div>
              ),
            }}
            onChange={(e) => {
              setValue(field.name, e);
              field.onChange(e);
            }}
            isLoading={isLoading}
            isDisabled={disabled}
            isClearable
            {...field}
          />
        )}

        {error && <Error>{error}</Error>}
      </div>
    </>
  );
};

Select.defaultProps = {
  label: "",
  sublabel: "",
  placeholder: "Select",
  placeholderColor: null,
  options: [],
  error: null,
  disabled: false,
  isLoading: false,
  isCreatable: false,
  isSimple: false,
  setValue: () => {},
  onChange: () => {},
  md: false,
  lg: false,
  menuPlacement: "bottom",
  menuPortalTarget: null,
};

export default Select;
