const Tooltip = ({ children, message }) => {
  return (
    <div className="relative inline-block group">
      <div>{children}</div>
      <div className="absolute hidden group-hover:flex bg-gray-700 text-white text-xs rounded py-1 px-2 z-[1000] bottom-full mb-2 w-[max-content] max-w-[300px]">
        {message}
      </div>
    </div>
  );
};

Tooltip.defaultProps = {
  message: "",
};

export default Tooltip;
