const Restricted = () => {
  return (
    <div className="text-center font-semibold">
      Looks like you do not have sufficient permission to view this page, please
      contact support if required.
    </div>
  );
};

export default Restricted;
