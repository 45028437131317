import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useFetchData, usePostData } from "../utils/ReactQueryHooks";
import * as request from "../utils/ApiClient";
import * as toast from "../utils/ApiToast";
import { isData } from "../utils/HookResponse";

export const useFirmwares = () =>
  useFetchData({
    key: "firmwares",
    url: "firmwares",
    data: {
      aId: Cookies.get("aId"),
    },
    options: {
      select: (data) => isData(data),
    },
    post: true,
  });

export const useFirmwareNotificationSubmit = () =>
  usePostData({ url: "firmwares/add-notification" });

export const useFirmwareDntSubmit = (setUploadProgress) =>
  useMutation({
    mutationFn: (data) => {
      data.aId = Cookies.get("aId");
      data.file = data.file[0];

      return request.post({
        url: "firmwares/upload-file",
        data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        options: {
          onUploadProgress: (progress) => {
            setUploadProgress(
              Math.round((progress.loaded * 100) / progress.total)
            );
          },
        },
      });
    },
    onSuccess: toast.successError,
    onError: toast.error,
  });

export const useFirmwareDntUpdate = () =>
  usePostData({ url: "firmwares/update-file" });
