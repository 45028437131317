import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";

import FileInput from "../../components/ui/Form/FileInput";
import Input from "../../components/ui/Form/Input";
import Label from "../../components/ui/Label/Label";
import Textarea from "../../components/ui/Form/Textarea";
import Button from "../../components/ui/Button/Button";
import Alert from "../../components/ui/Alert/Alert";
import Checkbox from "../../components/ui/Form/Checkbox";
import defaults from "../../config/forms.json";

import {
  useFirmwareDntSubmit,
  useFirmwareDntUpdate,
} from "../../hooks/useFirmware";

import Acknowledgement from "./Acknowledgement";

const DNT_INFO = {
  dntFirmwareFile: "",
  sha256: "",
  product: "",
  firmwareVersion: "",
  modelName: "",
  modelId: "",
  productVersion: "",
  messageDetails: "",
  messageComparison: "",
};

const CSS_DNT_TABLE = {
  table: "table-auto w-full text-left text-xs mt-3",
  item: "border border-gray-400 p-1",
  header: "p-1",
  title: "font-semibold",
  compare: "font-semibold text-red-500",
};

const FormFile = ({ firmwareDataRefetch, onClose }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileError, setFileError] = useState("");
  const [formsReadOnly, setFormsReadOnly] = useState(false);
  const [dntInfo, setDntInfo] = useState(DNT_INFO);
  const [uploadProgress, setUploadProgress] = useState(0);

  const {
    mutate: firmwareSubmitMutate,
    data: firmwareSubmitData,
    isSuccess: isFirmwareSubmitSuccess,
    isError: isFirmwareSubmitError,
  } = useFirmwareDntSubmit(setUploadProgress);

  const {
    mutate: firmwareUpdateMutate,
    isSuccess: isFirmwareUpdateSuccess,
    isError: isFirmwareUpdateError,
  } = useFirmwareDntUpdate();

  useEffect(() => {
    if (isFirmwareSubmitSuccess) {
      if (firmwareSubmitData.data.result === "failed") {
        setFileError(firmwareSubmitData.data.errorMessage);
        setFormsReadOnly(false);
        setUploadProgress(0);
      } else {
        setFileError("");
        setFormsReadOnly(true);

        if (firmwareSubmitData?.data.dntInfo?.dntCapabilities.length > 0) {
          const previousDnt = firmwareSubmitData.data.previousDntCapabilities;

          let messageComparison = null;

          if (previousDnt && previousDnt.length !== 0) {
            messageComparison =
              previousDnt.pending.existingDnt &&
              previousDnt.pending.existingDnt?.length > 0
                ? previousDnt.pending.existingDnt[0].dntCapabilities[0]
                : previousDnt.published.existingDnt &&
                  previousDnt.published.existingDnt.length > 0
                ? previousDnt.published.existingDnt[0].dntCapabilities[0]
                : null;
          }

          let dntInfoTemp =
            firmwareSubmitData?.data.dntInfo?.dntCapabilities[0];
          setDntInfo({
            dntFirmwareFile: firmwareSubmitData?.data.fileName,
            sha256: firmwareSubmitData?.data.dntInfo?.sha256,
            id: firmwareSubmitData?.data.id,
            product: dntInfoTemp.danteProduct,
            firmwareVersion: dntInfoTemp.danteFirmwareVersion,
            modelName: dntInfoTemp.oemModelName,
            modelId: dntInfoTemp.modelId,
            productVersion: dntInfoTemp.oemModelVersion,
            messageDetails: firmwareSubmitData.data.messageDetails,
            messageComparison: messageComparison,
          });
        }
      }
    }

    if (isFirmwareSubmitSuccess || isFirmwareSubmitError) {
      setIsSubmitting(false);
    }
  }, [isFirmwareSubmitSuccess, isFirmwareSubmitError]);

  useEffect(() => {
    if (isFirmwareUpdateSuccess) {
      handleResetForm();
      onClose();
      firmwareDataRefetch();
    }

    if (isFirmwareUpdateSuccess || isFirmwareUpdateError) {
      setIsSubmitting(false);
    }
  }, [isFirmwareUpdateSuccess, isFirmwareUpdateError]);

  const handleResetForm = () => {
    setUploadProgress(0);
    setFormsReadOnly(false);
    setFileError("");
    setDntInfo(DNT_INFO);

    reset();
  };

  const handleFileSubmit = (data) => {
    setIsSubmitting(true);

    if (formsReadOnly) {
      firmwareUpdateMutate({
        aId: Cookies.get("aId"),
        sha256: dntInfo.sha256,
        status: "submit",
        releaseNotesUrl: data.releaseNotesUrl,
        comments: data.comments,
        fileName: dntInfo.dntFirmwareFile,
        fileId: dntInfo.id,
        modelId: dntInfo.modelId,
        modelName: dntInfo.modelName,
        modelVersion: dntInfo.productVersion,
        product: dntInfo.product,
      });
    } else {
      firmwareSubmitMutate({
        file: data.firmwareFile,
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit((data) => handleFileSubmit(data))}
      className="grid grid-cols-2 gap-4"
    >
      {!formsReadOnly && (
        <div className="col-span-2">
          <Label>
            Please submit a separate form for DNT files corresponding to each
            product family that you need to upload.
          </Label>
        </div>
      )}

      {formsReadOnly &&
        dntInfo.messageDetails &&
        dntInfo.messageDetails !== "" &&
        dntInfo.messageComparison && (
          <div className="col-span-2 mb-3">
            <Alert warning title="Note">
              {dntInfo.messageDetails}

              <table className={CSS_DNT_TABLE.table}>
                <thead>
                  <tr>
                    <th></th>
                    <th className={CSS_DNT_TABLE.header}>Existing file</th>
                    <th className={CSS_DNT_TABLE.header}>Your uploaded file</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={CSS_DNT_TABLE.title}>Manufacturer</td>
                    <td className={CSS_DNT_TABLE.item}>
                      {dntInfo.messageComparison.manufacturerName || "-"}
                    </td>
                    <td className={CSS_DNT_TABLE.item}>
                      {dntInfo.messageComparison.manufacturerName || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className={CSS_DNT_TABLE.title}>
                      Dante Product Family
                    </td>
                    <td className={CSS_DNT_TABLE.item}>
                      {dntInfo.messageComparison.danteProduct || "-"}
                    </td>
                    <td className={CSS_DNT_TABLE.item}>{dntInfo.product}</td>
                  </tr>
                  <tr>
                    <td className={CSS_DNT_TABLE.title}>
                      Dante Firmware Version
                    </td>
                    <td
                      className={`${CSS_DNT_TABLE.item} ${
                        dntInfo.messageComparison.danteFirmwareVersion !==
                          dntInfo.firmwareVersion && CSS_DNT_TABLE.compare
                      }`}
                    >
                      {dntInfo.messageComparison.danteFirmwareVersion || "-"}
                    </td>
                    <td
                      className={`${CSS_DNT_TABLE.item} ${
                        dntInfo.messageComparison.danteFirmwareVersion !==
                          dntInfo.firmwareVersion && CSS_DNT_TABLE.compare
                      }`}
                    >
                      {dntInfo.firmwareVersion || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className={CSS_DNT_TABLE.title}>Product Model Name</td>
                    <td
                      className={`${CSS_DNT_TABLE.item} ${
                        dntInfo.messageComparison.oemModelName !==
                          dntInfo.modelName && CSS_DNT_TABLE.compare
                      }`}
                    >
                      {dntInfo.messageComparison.oemModelName || "-"}
                    </td>
                    <td
                      className={`${CSS_DNT_TABLE.item} ${
                        dntInfo.messageComparison.oemModelName !==
                          dntInfo.modelName && CSS_DNT_TABLE.compare
                      }`}
                    >
                      {dntInfo.modelName || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className={CSS_DNT_TABLE.title}>Model ID</td>
                    <td
                      className={`${CSS_DNT_TABLE.item} ${
                        dntInfo.messageComparison.modelId !== dntInfo.modelId &&
                        CSS_DNT_TABLE.compare
                      }`}
                    >
                      {dntInfo.messageComparison.modelId || "-"}
                    </td>
                    <td
                      className={`${CSS_DNT_TABLE.item} ${
                        dntInfo.messageComparison.modelId !== dntInfo.modelId &&
                        CSS_DNT_TABLE.compare
                      }`}
                    >
                      {dntInfo.modelId || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className={CSS_DNT_TABLE.title}>Product Version</td>
                    <td
                      className={`${CSS_DNT_TABLE.item} ${
                        dntInfo.messageComparison.oemModelVersion !==
                          dntInfo.productVersion && CSS_DNT_TABLE.compare
                      }`}
                    >
                      {dntInfo.messageComparison.oemModelVersion || "-"}
                    </td>
                    <td
                      className={`${CSS_DNT_TABLE.item} ${
                        dntInfo.messageComparison.oemModelVersion !==
                          dntInfo.productVersion && CSS_DNT_TABLE.compare
                      }`}
                    >
                      {dntInfo.productVersion || "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Alert>
          </div>
        )}

      <div className="col-span-2">
        <Input
          label="Manufacturer"
          value={Cookies.get("manufacturer")}
          readOnly
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <Input
          label="Release Notes URL"
          placeholder="Enter Release Notes URL"
          disabled={formsReadOnly || isSubmitting}
          sublabel="optional"
          validation={{
            name: "releaseNotesUrl",
            register,
            rules: {
              maxLength: {
                value: defaults.input.maxLength,
                message: `URL cannot exceed ${defaults.input.maxLength} characters`,
              },
            },
          }}
          error={errors.releaseNotesUrl?.message}
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <Textarea
          label="Comments"
          placeholder="Enter Comments"
          disabled={formsReadOnly || isSubmitting}
          sublabel="optional"
          rows="1"
          validation={{
            name: "comments",
            register,
          }}
        />
      </div>
      {!formsReadOnly && (
        <div className="col-span-2">
          <FileInput
            label="DNT Firmware File"
            guide="Note: Please do not upload test firmware, upload production firmware only. Uploaded files will be visible to all users of the Dante Updater application."
            disabled={isSubmitting}
            validation={{
              name: "firmwareFile",
              register,
              rules: {
                required: "DNT Firmware File is required.",
                validate: (value) => {
                  if (!value[0]) return true;
                  const acceptedFileType = "dnt";
                  const fileExtension = value[0].name.split(".").pop();
                  const isFileTypeValid =
                    fileExtension === acceptedFileType.toLowerCase();

                  const maxSize = defaults.file.maxSize.dnt * 1024 * 1024;
                  const isFileSizeValid = value[0].size <= maxSize;

                  if (!isFileTypeValid)
                    return "DNT Firmware File type must be .dnt";
                  if (!isFileSizeValid)
                    return `DNT Firmware File size cannot exceed ${defaults.file.maxSize.dnt}MB`;
                  return true;
                },
              },
            }}
            accept=".dnt"
            error={
              errors.firmwareFile
                ? errors.firmwareFile.message
                : fileError !== ""
                ? fileError
                : null
            }
          />
        </div>
      )}
      {formsReadOnly && (
        <>
          <div className="col-span-2 md:col-span-1">
            <Input
              label="DNT Firmware File"
              value={dntInfo.dntFirmwareFile}
              disabled
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <Input
              label="Dante Product Family"
              value={dntInfo.product}
              disabled
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <Input
              label="Dante Firmware Version"
              value={dntInfo.firmwareVersion}
              disabled
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <Input
              label="Product Model Name"
              value={dntInfo.modelName}
              disabled
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <Input
              label="Product Version"
              value={dntInfo.productVersion}
              disabled
            />
          </div>
          <div className="col-span-2">
            <Acknowledgement />
            <div className="mt-5">
              <Checkbox
                label="I agree"
                value={1}
                validation={{
                  name: "agree",
                  register,
                  rules: { required: "I agree field is required." },
                }}
                error={errors.agree?.message}
              />
            </div>
          </div>
        </>
      )}

      <div className="col-span-2 md:flex justify-end gap-2">
        {!formsReadOnly && (
          <>
            <Button
              type="submit"
              solid
              primary
              isLoading={isSubmitting}
              progress={uploadProgress}
              progressLabel={
                uploadProgress !== 100
                  ? `Uploading (${uploadProgress}%)`
                  : `Finalizing...`
              }
              className="w-full md:w-auto mt-3"
            >
              Submit Firmware File
            </Button>
          </>
        )}

        {formsReadOnly && (
          <>
            <Button
              type="submit"
              solid
              primary
              isLoading={isSubmitting}
              className="w-full md:w-auto mt-3"
            >
              Confirm and Finalize Submission
            </Button>
          </>
        )}
        <Button
          onClick={handleResetForm}
          solid
          secondary
          disabled={isSubmitting}
          className="w-full md:w-auto mt-3"
        >
          {!formsReadOnly ? "Reset Form" : "Cancel and Upload Another"}
        </Button>
        <Button onClick={onClose} solid gray className="w-full md:w-auto mt-3">
          Close
        </Button>
      </div>
    </form>
  );
};

export default FormFile;
