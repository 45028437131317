import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import { Toaster } from "sonner";
import Header from "./Header";
import { autoLogoff } from "../../hooks/useWebSocket";

const MainLayout = () => {
  autoLogoff();

  return (
    <>
      <Header />
      <main>
        <div className="p-5 h-screen">
          <div className="container mx-auto py-5">
            <Outlet />
          </div>
        </div>
      </main>
      <Toaster richColors position="top-right" />
      <Footer />
    </>
  );
};

export default MainLayout;
