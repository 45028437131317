import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

export const PermissionRoute = ({ children, requiredPermission }) => {
  if (!Cookies.get("user_permission").split("-").includes(requiredPermission)) {
    return <Navigate to="/restricted" replace />;
  }

  return children;
};
