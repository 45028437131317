import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AutoLogout = () => {
  const [seconds, setSeconds] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev) => prev - 1);
    }, 1000);

    if (seconds <= 0) {
      clearInterval(interval);
      navigate("/");
    }

    return () => clearInterval(interval);
  }, [seconds, navigate]);

  return (
    <div>
      <div className="h-screen flex items-center justify-center">
        <div className="text-center">
          <img src="/oops.gif" alt="" className="m-auto w-[200px]" />
          <div className="mb-5">
            Your company administrator has changed permissions associated with
            your profile.
            <br />
            Please login again to continue accessing the portal.
          </div>
          <div className="font-bold pt-5 pb-4">
            You will be automatically redirected to login page in {seconds}{" "}
            seconds
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoLogout;
