import { useAuth0 } from "@auth0/auth0-react";
import { HiChevronDown } from "react-icons/hi";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

const MY_SITE_URL = window.env.REACT_APP_URL_MY_SITE;
const NAV_DATA = [
  {
    name: "Home",
    url: `${MY_SITE_URL}/s`,
    children: null,
  },
  {
    name: "Support",
    url: null,
    children: [
      {
        name: "Cases",
        url: `${MY_SITE_URL}/s/case/Case/Default`,
      },
      {
        name: "Contact Us",
        url: `${MY_SITE_URL}/s/contact-support`,
      },
    ],
  },
  {
    name: "Tools",
    url: null,
    children: [
      {
        name: "Module Configuration Tool",
        url: "https://module-config.audinate.com/dnaLogin",
      },
    ],
  },
  {
    name: "News & Events",
    url: null,
    children: [
      {
        name: "Press Releases",
        url: `${window.env.REACT_APP_URL_AUDINATE}/press/`,
      },
      {
        name: "Events",
        url: `${MY_SITE_URL}/s/events`,
      },
    ],
  },
  {
    name: "Documentation & Downloads",
    url: null,
    children: [
      {
        name: "OEM Home",
        url: window.env.REACT_APP_URL_OEM,
      },
      {
        name: "Your Releases",
        url: window.env.REACT_APP_URL_HUBSHARE,
      },
    ],
  },
  {
    name: "Resources",
    url: null,
    children: [
      {
        name: "Dante Updater",
        url: `${MY_SITE_URL}/s/dante-updater`,
      },
      {
        name: "Compliance",
        url: `${MY_SITE_URL}/s/documentation/compliance`,
      },
      {
        name: "Policy & Guidelines",
        url: `${MY_SITE_URL}/s/documentation/policy-and-guidelines`,
      },
      {
        name: "Special Pricing Form",
        url: `${MY_SITE_URL}/s/special-pricing-forms`,
      },
      {
        name: "Archives",
        url: `${MY_SITE_URL}/s/archives`,
      },
    ],
  },
  {
    name: "Marketing",
    url: null,
    children: [
      {
        name: "Branding Guide",
        url: `${MY_SITE_URL}/s/dante-brand-guidelines`,
      },
      {
        name: "Logo",
        url: `${MY_SITE_URL}/s/logos`,
      },
      {
        name: "Training",
        url: `${MY_SITE_URL}/s/training`,
      },
      {
        name: "Webforms",
        url: `${MY_SITE_URL}/s/webforms`,
      },
    ],
  },
];

const Header = () => {
  const { isAuthenticated, logout } = useAuth0();

  return (
    <div className="my__header">
      <div className="my__forceCommunityThemeHeaderCompact my__forceCommunityThemeHeaderBase">
        <div className="my__themeBgImage"></div>
        <div className="my__themeBottomBorder"></div>
        <div className="my__themeHeaderInner">
          <div className="my__themeLogo">
            <a className="my__forceCommunityThemeLogo">
              <div className="my__logoImage"></div>
              <span className="my__slds-assistive-text">OEM Portal</span>
            </a>
          </div>
          {isAuthenticated && (
            <>
              <div className="my__themeNav">
                <ul className="my__nav">
                  {NAV_DATA.map((item, key) => (
                    <li key={key}>
                      {item.url && (
                        <Link to={item.url}>
                          <button>{item.name}</button>
                        </Link>
                      )}
                      {!item.url && (
                        <button>
                          {item.name}{" "}
                          <HiChevronDown className="inline-block text-xl" />
                        </button>
                      )}
                      {item.children && (
                        <ul>
                          {item.name == "Tools" &&
                            Cookies.get("user_permission")
                              .split("-")
                              .includes("licenseView") && (
                              <li>
                                <Link to="/license">Licenses</Link>
                              </li>
                            )}
                          {item.name == "Tools" &&
                            Cookies.get("user_permission")
                              .split("-")
                              .includes("firmwareUpload") && (
                              <li>
                                <Link to="/firmware">Firmware Manager</Link>
                              </li>
                            )}
                          {item.children.map((submenu, key2) => (
                            <li key={key2}>
                              <Link to={submenu.url}>{submenu.name}</Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="my__themeUtils">
                <div className="my__themeUtilsContainer">
                  <img
                    className="w-[24px] h-[24px] rounded-full"
                    src="/avatar.png"
                    alt=""
                  />
                  <button className="my__themeProfileMenu">
                    {Cookies.get("user_name")}
                  </button>
                  <ul>
                    {Cookies.get("user_permission")
                      .split("-")
                      .includes("userManagement") && (
                      <li>
                        <Link to="/user">Manage Users</Link>
                      </li>
                    )}
                    <li>
                      <a
                        onClick={() =>
                          logout({
                            logoutParams: {
                              returnTo: window.location.origin,
                            },
                          })
                        }
                      >
                        Log Out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
