import Label from "./Label";

const LabelData = ({ children, label, ...rest }) => {
  return (
    <div className="pb-5" {...rest}>
      <Label sm className="font-semibold">
        {label}
      </Label>
      <p>{children}</p>
    </div>
  );
};

export default LabelData;
