import { useEffect, useState } from "react";
import {
  HiOutlineFolder,
  HiOutlineFolderOpen,
  HiChevronRight,
  HiOutlineFolderRemove,
} from "react-icons/hi";
import "./TreeView.css";

const highlightNodeText = (text, searchString) => {
  if (!text || !searchString) return text;

  const escapedRegexString = new RegExp(
    `(${searchString.replace(/([.*+?^${}()|[\]\\/\\])/g, "\\$1")})`,
    "gi"
  );
  const parts = text.split(escapedRegexString);

  return parts.map((part, index) => {
    if (part.toLowerCase() === searchString.toLowerCase()) {
      return (
        <span key={index} className="tree-node-highlighted">
          {part}
        </span>
      );
    }
    return part;
  });
};

const filterNode = (data, searchString, config) => {
  if (!data || typeof data !== "object") return null;

  const filteredChildren = (data[config.children] || [])
    .map((child) => filterNode(child, searchString, config))
    .filter(Boolean);

  if (data[config.parent]?.toLowerCase().includes(searchString.toLowerCase())) {
    const highlightedName = highlightNodeText(
      data[config.parent],
      searchString
    );

    return {
      ...data,
      [config.parent]: highlightedName,
      [config.children]: filteredChildren,
    };
  }

  if (filteredChildren.length > 0) {
    return {
      ...data,
      [config.children]: filteredChildren,
    };
  }

  return null;
};

const TreeNode = ({
  config,
  node,
  activeNode,
  setActiveNode,
  onSelectNode,
  expanded,
  expandAll,
}) => {
  const [isOpen, setIsOpen] = useState(expanded);

  useEffect(() => {
    setIsOpen(expanded || expandAll);
  }, [expandAll]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleNodeClick = () => {
    setActiveNode(node[config.id]);
    onSelectNode(config.toReturn.map((key) => node[key]));
  };

  return (
    <div className="ml-1">
      <div
        className={`flex px-1 items-baseline cursor-pointer ${
          activeNode === node[config.id] ? "tree-node-active" : ""
        }`}
        onClick={() => {
          handleToggle();
          handleNodeClick();
        }}
      >
        <span className="mt-1">
          {isOpen && node[config.children]?.length > 0 ? (
            <HiOutlineFolderOpen />
          ) : (
            <HiOutlineFolder />
          )}
        </span>
        <span
          className={`${node[config.children]?.length > 0 ? "" : "mr-3"} ${
            isOpen ? "transform rotate-90" : ""
          }`}
        >
          {node[config.children]?.length > 0 && <HiChevronRight />}
        </span>
        <div>{node[config.parent]}</div>
      </div>
      <div
        className={`tree-node ${
          isOpen ? "tree-node-expanded" : "tree-node-collapsed"
        }`}
      >
        {isOpen && node[config.children] && (
          <div className="pl-2 border-l border-gray-400 ml-2">
            {node[config.children]?.map((childNode) => (
              <TreeNode
                key={childNode[config.id]}
                config={config}
                node={childNode}
                activeNode={activeNode}
                setActiveNode={setActiveNode}
                onSelectNode={onSelectNode}
                expandAll={expandAll}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const TreeView = ({
  data,
  dataFilter,
  config,
  className,
  onSelectNode,
  expandFirst,
}) => {
  const [activeNode, setActiveNode] = useState();
  const [items, setItems] = useState(data);

  useEffect(() => {
    if (expandFirst) {
      setActiveNode(data[config.id]);
      onSelectNode(config.toReturn.map((key) => data[key]));
    }
  }, []);

  useEffect(() => {
    setItems(filterNode(data, dataFilter, config));
  }, [dataFilter]);

  return (
    <div className={className}>
      {items &&
        [items]?.length > 0 &&
        [items].map((node) => (
          <TreeNode
            key={node[config.id]}
            config={config}
            node={node}
            activeNode={activeNode}
            setActiveNode={setActiveNode}
            onSelectNode={onSelectNode}
            expanded={expandFirst}
            expandAll={dataFilter !== ""}
          />
        ))}
      {!items && (
        <div className="flex ml-1 px-1">
          <span className="mt-1">
            <HiOutlineFolderRemove />
          </span>
          <span className="ml-2">No result found.</span>
        </div>
      )}
    </div>
  );
};

TreeView.defaultProps = {
  data: {},
  dataFilter: "",
  config: {
    id: "id",
    parent: "parent",
    children: "children",
    toReturn: [],
  },
  className: "",
  onSelectNode: () => {},
  expandFirst: false,
};

TreeNode.defaultProps = {
  expanded: false,
  expandAll: false,
};

export default TreeView;
