export const buttonSize = {
  sm: "h-6 px-2 text-xs",
  md: "h-8 px-3 text-sm",
  lg: "h-10 px-4 text-md",
};

export const solidTheme = {
  disabled:
    "hover:cursor-not-allowed border-gray-muted bg-gray-muted text-white outline-none focus:outline-none",
  primary:
    "border-primary bg-primary text-white hover:border-primary-dark hover:bg-primary-dark active:border-primary-dark active:bg-primary-dark",
  secondary:
    "border-secondary bg-secondary text-white hover:border-secondary-dark hover:bg-secondary-dark active:border-secondary-dark active:bg-secondary-dark",
  danger:
    "border-danger bg-danger text-white hover:border-danger-dark hover:bg-danger-dark hover:text-white hover:shadow-button-shadow active:border-danger active:bg-danger active:shadow-button-inner",
  gray: "border-gray bg-gray text-white hover:border-gray-dark hover:bg-gray-dark hover:text-white hover:shadow-button-shadow active:border-gray active:bg-gray active:shadow-button-inner",
};

export const outlineTheme = {
  disabled:
    "hover:cursor-not-allowed border border-gray-light bg-white text-gray-light outline-none focus:outline-none",
  primary:
    "border border-primary bg-white text-primary hover:border-primary-dark hover:bg-primary-muted hover:text-primary-dark hover:shadow-button-shadow active:border-primary-dark active:bg-primary-muted active:shadow-button-inner",
  secondary:
    "border border-secondary bg-white text-secondary hover:border-secondary-dark hover:bg-secondary-muted hover:text-secondary-dark hover:shadow-button-shadow active:border-secondary-dark active:bg-secondary-muted active:shadow-button-inner",
  danger:
    "border border-black bg-white text-black hover:bg-danger-muted hover:shadow-button-shadow active:bg-danger-muted active:shadow-button-inner",
  gray: "border border-black bg-white text-black hover:bg-gray-muted hover:shadow-button-shadow active:bg-gray-muted active:shadow-button-inner",
};

export const textTheme = {
  disabled:
    "hover:cursor-not-allowed rounded-sm border-none bg-transparent text-gray-light outline-none focus:outline-none",
  primary: "rounded-sm border-none bg-transparent text-primary hover:underline",
  secondary:
    "rounded-sm border-none bg-transparent text-secondary hover:underline",
  danger: "rounded-sm border-none bg-transparent text-danger hover:underline",
  gray: "rounded-sm border-none bg-transparent text-gray hover:underline",
};
