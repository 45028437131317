import Label from "../../components/ui/Label/Label";

const Acknowledgement = ({ children }) => {
  return (
    children || (
      <>
        <Label className="mt-5 font-bold">Terms and Conditions </Label>
        <Label className="mt-2">
          By marking the checkbox below, you acknowledge that:
        </Label>
        <ul className="text-xs list-disc ml-5">
          <li>
            You are an employee of the manufacturer listed above
            (“Manufacturer“).
          </li>
          <li>
            You have the authority to agree to these Terms and Conditions on
            behalf of Manufacturer, or if not, that you have obtained the
            written permission of an authorised representative.
          </li>
          <li>
            The firmware has been validated and the Manufacturer takes
            responsibility for any issues resulting from this firmware being
            used to update Manufacturer&apos;s products in the field.
            Specifically Manufacturer does not hold Audinate Pty Ltd or its
            associates (“Audinate“) liable for any loss, damage, expense or
            injury that may arise as a result of its use of this service.
          </li>
          <li>
            Manufacturer authorises Audinate to distribute the firmware and
            update Dante devices.
          </li>
        </ul>
      </>
    )
  );
};

export default Acknowledgement;
