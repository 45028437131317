import { useId } from "react";
import Error from "../Label/Error";

const Checkbox = ({ label, validation, error, ...rest }) => {
  const tempId = useId();
  return (
    <>
      <div className="mt-1 flex items-center mb-4 gap-2 mr-2">
        <input
          id={tempId}
          aria-label={validation?.name}
          type="checkbox"
          value=""
          className="w-[15px] h-[15px]"
          {...validation?.register(validation.name, validation.rules)}
          {...rest}
        />
        <label htmlFor={tempId} className="text-sm">
          {label}
        </label>
        {error && <Error>{error}</Error>}
      </div>
    </>
  );
};

Checkbox.defaultProps = {
  label: "",
};

export default Checkbox;
