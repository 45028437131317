import { useFetchData } from "../utils/ReactQueryHooks";

export const usePermission = () =>
  useFetchData({
    key: "permission",
    url: "permissions",
    options: {
      enabled: false,
      select: (data) => {
        if (data?.status == "failed") {
          return {
            status: data.status,
          };
        }

        return {
          type: data.permissions,
          aId: data.aId,
          userName: data.userName,
          manufacturer: data.manufacturer,
        };
      },
    },
    post: true,
  });
