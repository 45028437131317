const Skeleton = () => {
  return (
    <div className="rounded-lg p-4 animate-pulse">
      <div className="h-4 bg-gray-200 mb-2 rounded"></div>
      <div className="h-4 bg-gray-200 mb-2 rounded w-5/6"></div>
      <div className="h-4 bg-gray-200 mb-2 rounded w-3/4"></div>
    </div>
  );
};

export default Skeleton;
