import Cookies from "js-cookie";
import { useFetchData, usePostData } from "../utils/ReactQueryHooks";
import { isData } from "../utils/HookResponse";

export const useUsers = () =>
  useFetchData({
    key: "users",
    url: "users",
    data: {
      aId: Cookies.get("aId"),
    },
    options: {
      select: (data) => isData(data),
    },
    post: true,
  });

export const useUserOffboard = () => usePostData({ url: "users/offboard" });

export const useUserUpdate = () => usePostData({ url: "users/update" });
