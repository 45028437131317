import { infoTheme, warningTheme, dangerTheme } from "./theme";
import clsx from "clsx";

const Alert = ({
  title,
  children,
  className,

  warning,
  danger,

  ...rest
}) => {
  const buildThemeClass = () => {
    if (warning) return warningTheme;
    if (danger) return dangerTheme;

    return infoTheme;
  };

  const buildClassDefault = {
    alertBody: `${buildThemeClass().body} px-4 py-3 rounded relative text-xs`,
  };

  const buildClassName = () => {
    return clsx(className);
  };

  return (
    <div
      className={clsx(buildClassDefault.alertBody, buildClassName())}
      {...rest}
      role="alert"
    >
      {title !== "" && (
        <strong className="font-bold block mb-3 text-sm">{title}</strong>
      )}
      <span className="block sm:inline whitespace-pre-line">{children}</span>
    </div>
  );
};

Alert.defaultProps = {
  title: "",
  className: "",

  warning: false,
  danger: false,
};

export default Alert;
