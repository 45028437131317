import Cookies from "js-cookie";
import { useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const autoLogoff = () => {
  const wsRef = useRef(null);
  const { logout } = useAuth0();

  useEffect(() => {
    const encodedClientId = encodeURIComponent(Cookies.get("aId"));

    wsRef.current = new WebSocket(
      `${window.env.REACT_APP_BE_WS}ws?id=${encodedClientId}`
    );

    wsRef.current.onmessage = (event) => {
      if (event.data == "logoff") {
        logout({
          logoutParams: {
            returnTo: `${window.location.origin}/auto-logout`,
          },
        });
      }
    };

    wsRef.current.onopen = () => {};
    wsRef.current.onclose = () => {};

    return () => {
      if (wsRef.current.readyState === 1) {
        wsRef.current.close();
      }
    };
  }, []);
};
