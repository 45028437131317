import "react-tagsinput/react-tagsinput.css";
import "./MultiInput.css";
import TagsInput from "react-tagsinput";
import Label from "../Label/Label";
import Error from "../Label/Error";

const MultiInput = ({
  label,
  placeholder,
  value,
  onChange,
  error,
  ...rest
}) => {
  return (
    <div>
      <Label>{label}</Label>
      <TagsInput
        value={value}
        onChange={onChange}
        addOnBlur
        inputProps={{ placeholder: placeholder }}
        className={error ? "react-tagsinput is-error" : "react-tagsinput"}
        {...rest}
      />
      {error && <Error>{error}</Error>}
    </div>
  );
};

MultiInput.defaultProps = {
  label: "",
  placeholder: "",
  error: "",
  value: [],
  onChange: () => {},
};

export default MultiInput;
