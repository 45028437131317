export const MESSAGES = {
  TESTING: {
    TEXT: "Some Text",
    CHILDREN: "Able to display children text.",
    CLASS: "Able to render className values",
    CONTENT: "Able to display content.",
    HIDE: "Able to hide content.",
    DISABLED: "Able to display disabled property.",
    CLICK: "Able to trigger onClick.",
    CHECKED: "Able to trigger check.",
    UNCHECKED: "Able to trigger uncheck.",
    TYPE: "Able to render type value",
    TYPE_DEFAULT: "Able to render default type value",
    VALUE: "Able to set value.",
    LIST: "Able to display list.",
    FORM: "Able to display form fields.",
    FORM_FILLABLE: "Able to fill up form fields.",
    PEMISSION_INVALID: "Does not accept invalid permission.",
  },
  HELP: {
    NOTIFICATION_THRESHOLD:
      "Set pool balance threshold to control when to receive an email notification. The notification will be sent out to the emails configured below when the pool balance drops below the threshold value.",
    PRODUCT_MODEL_NAME:
      "This is the model number that will appear in Dante Updater. Don’t include the manufacturer name.",
  },
};
