export const infoTheme = {
  body: "bg-blue-50 text-blue-900",
};

export const warningTheme = {
  body: "bg-yellow-100 text-amber-950",
};

export const dangerTheme = {
  body: "bg-red-50 text-red-900",
};
